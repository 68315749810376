<!--
 * @Author: 朱廷果 1028509503@qq.com
 * @Date: 2022-08-19 15:49:39
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2023-02-08 09:53:14
 * @FilePath: \dataview-next\src\manage-views\org\dept\components\Main.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="dept-main">
    <div class="main-title">
      <div class="main-label">组织架构</div>
      <div>
        <el-button
          v-if="judgingAPermission(['dept.store'])"
          size="mini"
          type="success"
          icon="el-icon-office-building"
          @click="goCompany()"
          >加入企业</el-button
        >
        <el-button
          v-if="judgingAPermission(['dept.store'])"
          size="mini"
          type="primary"
          icon="el-icon-plus"
          @click="dialog = true"
          >新增</el-button
        >
      </div>
    </div>
    <el-table
      v-loading="loading"
      :data="deptData"
      class="dept-table"
      :header-cell-style="{ background: '#fafafa' }"
      row-key="id"
      :default-expand-all="true"
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="name" width="260" label="组织名称">
        <template #default="{ row }">
          <div class="archi-label">
            <span class="archi-label-svg">
              <svg aria-hidden="true" class="color-icon">
                <use :xlink:href="getIcon(row)" />
              </svg>
              <el-tooltip
                v-if="row.name.length > 12"
                :content="row.name"
                effect="dark"
                :open-delay="500"
                placement="top"
              >
                <span>{{ row.name }}</span>
              </el-tooltip>
              <span v-else>{{ row.name }}</span>
            </span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="code" label="组织编码"></el-table-column>
      <el-table-column prop="charge_person" label="负责人"></el-table-column>
      <el-table-column prop="phone" label="联系电话"></el-table-column>
      <el-table-column label="状态" width="70">
        <template>
          <!-- 暂时不用状态 后端容易出问题 先写死 -->
          <!-- <div>
              <span class="table-state" :style="{backgroundColor: row.status?'#1890ff':'#f11111',color: row.status?'#1890ff':'#f11111'}">
              </span>
              <span>{{row.status?'正常':'停用'}}
              </span>
            </div> -->
          <div>
            <span class="table-state"> </span>
            <span>正常 </span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="sort" label="排序" width="70"></el-table-column>
      <el-table-column
        label="创建时间"
        prop="created_at"
        align="left"
        width="150px"
      ></el-table-column>
      <el-table-column label="操作" width="200px">
        <template #default="{ row }">
          <el-button
            v-if="judgingAPermission(['dept.update'])"
            size="mini"
            type="text"
            @click="onEdit(row)"
            >修改</el-button
          >
          <el-button
            v-if="judgingAPermission(['dept.store'])"
            class="table-add-btn"
            size="mini"
            type="text"
            @click="onAdd(row)"
            >添加子部门</el-button
          >
          <el-popconfirm
            v-if="judgingAPermission(['dept.delete'])"
            title="确定要删除这个部门吗？ "
            @confirm="onDelete(row)"
            class="delete-btn"
          >
            <el-button
              v-if="row.parent_id !== 0"
              size="mini"
              type="text"
              slot="reference"
              >删除</el-button
            >
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <!-- 编辑抽屉 -->
    <EditDrawer
      :drawer="drawer"
      :editData="editData"
      @drawerClose="drawerClose"
      @drawerSave="drawerSave"
    >
    </EditDrawer>
    <!-- 新增弹窗 -->
    <AddDialog
      :dialog="dialog"
      :parentParam="parentData"
      @dialogClose="dialogClose"
      @dialogSave="dialogSave"
    >
    </AddDialog>
  </div>
</template>

<script>
import { getDept, detDept } from "@/api/saasManage";
import { Popconfirm } from "element-ui";
import { judgingAPermission } from "@/utils/tools";
import EditDrawer from "./EditDrawer.vue";
import AddDialog from "./AddDialog.vue";
export default {
  components: {
    EditDrawer,
    AddDialog,
    "el-popconfirm": Popconfirm,
  },
  data() {
    return {
      judgingAPermission: judgingAPermission,
      loading: false,
      deptData: [],
      drawer: false,
      dialog: false,
      editData: {},
      parentData: {},
      searchList: {},
      search: "",
      // 搜索字段
      searchText: "",
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    goCompany(){
      let menuTree = JSON.parse(sessionStorage.getItem("saasMenu"));
      let data
      const loopMenuTree = (array) =>{
        for (let index = 0; index < array.length; index++) {
          if(array[index].route && array[index].route ==='/manage/config'){
            data = array[index]
          }
          if(!data && array[index].children && array[index].children.length){
            loopMenuTree(array[index].children)
          }
        }
      }
      if(menuTree && menuTree.length){
        loopMenuTree(menuTree)
      }
      // menuTree.
      this.$emit('toAppUrl',data)
      this.$router.push({
        name:'ManageConfig',
        query: {
          name:'apply'
        }
      })
    },
    getIcon(data) {
      const group = [1, 2];
      const company = [3, 4];
      const project = [5, 6];
      const section = [7];
      if (!data) {
        return "#iconjituan";
      }
      if (group.indexOf(+data.type) !== -1) {
        return "#iconjituan";
      } else if (company.indexOf(+data.type) !== -1) {
        return "#icongongsi";
      } else if (project.indexOf(+data.type) !== -1) {
        return "#iconxiangmu";
      } else if (section.indexOf(+data.type) !== -1) {
        return "#iconbumen";
      }
    },
    /**
     * @desc:关闭新增弹窗
     */
    dialogClose() {
      this.dialog = !this.dialog;
    },
    dialogSave() {
      this.dialog = !this.dialog;
      this.getData();
    },
    drawerSave() {
      this.drawer = !this.drawer;
      this.getData();
    },
    /**
     * @desc:关闭编辑抽屉
     */
    drawerClose() {
      this.drawer = !this.drawer;
    },
    /**
     * @desc:打开编辑弹窗
     */
    onEdit(row) {
      this.editData = row;
      this.drawer = !this.drawer;
    },
    onAdd(row) {
      const { name, id } = row;
      this.parentData = {
        parent_name: name,
        parent_id: id,
      };
      this.dialog = !this.dialog;
    },
    onDelete(row) {
      detDept({ id: [row.id] })
        .then((res) => {
          if (res.data && res.data.code !== 200) {
            return false;
          }
          this.getData();
        })
        .catch(() => {
          this.$message.error(`操作错误`);
        });
    },
    /**
     * @desc:筛选
     */
    onSearch(data) {
      // this.search = data;
      this.searchList = [];
      this.searchText = data;
      this.getData();
    },
    onRefresh() {
      this.search = "";
      this.searchText = "";
      this.getData();
    },
    /**
     * @desc: 筛选数据
     */
    filterItem(item, name) {
      if (item.name.indexOf(name) !== -1) return true;
      if (item.children) {
        item.children = item.children.filter((sonEle) => {
          return this.filterItem(sonEle, name);
        });
        if (item.children.length > 0) {
          return true;
        }
      }
      return false;
    },
    /**
     * @desc: 得到部门管理列表
     */
    getData() {
      this.loading = true;
      let params = {
        name: this.search,
      };
      getDept(params)
        .then((res) => {
          if (res.data && res.data.code !== 200) {
            return false;
          }
          this.deptData = res.data.data.data;
          if (this.searchText) {
            this.deptData = this.deptData.filter((item) => {
              return this.filterItem(item, this.searchText);
            });
          }
          this.loading = false;
        })
        .catch(() => {
          this.$message.error(`操作错误`);
        });
    },
  },

};
</script>
<style lang="less">
@import "../../../../css/manageMain.less";
.dept-main {
  background-color: #fff;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  border-radius: 8px;
  .dept-table {
    width: 100%;
    flex: 1;
    padding: 0 15px;
    overflow: auto;
  }
  .archi-label {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    -moz-user-select: none; /* Firefox私有属性 */
    -webkit-user-select: none; /* WebKit内核私有属性 */
    -ms-user-select: none; /* IE私有属性(IE10及以后) */
    -khtml-user-select: none; /* KHTML内核私有属性 */
    -o-user-select: none; /* Opera私有属性 */
    user-select: none; /* CSS3属性 */
    .archi-label-svg {
      width: 100%;
      display: flex;
      align-items: center;
      overflow: hidden;
      span {
        flex: 1;
        overflow: hidden;
        padding-right: 6px;
        box-sizing: border-box;
      }
      img {
        width: 20px;
        height: 20px;
        margin-right: 6px;
      }
    }
    .color-icon {
      width: 20px;
      height: 20px;
      margin-right: 6px;
    }
  }
  .el-table .cell {
    display: flex !important;
  }
}
</style>
