<!--
 * @Author: 朱廷果 1028509503@qq.com
 * @Date: 2022-08-25 14:52:04
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2022-12-30 14:05:40
 * @FilePath: \dataview-next\src\manage-views\views\org\dept\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class='dept-container'>
    <Header @onSearch='onSearch' @onRefresh='onRefresh' title="组织"></Header>
    <Main ref='main' @toAppUrl='toAppUrl'></Main>
  </div>
</template>
<script >
import Header from '@/manage-views/components/header/Index.vue'
import Main from './components/Main'
export default {
  components: { 
    Header, 
    Main
  },
  methods: {
    onSearch(data){
      this.$refs.main.onSearch(data);
    },
    onRefresh(data){
      this.$refs.main.onRefresh(data);
    },
    toAppUrl(data){
      this.$emit('toAppUrl', data);
    }
  }
};
</script>
<style lang='less'>
.dept-container{
  width:100%;
  height:100%;
  display: flex;
  flex-direction: column;
}
</style>