<template>
  <div class="dept-edit-main">
    <el-drawer
      title="修改部门"
      :visible.sync="drawers"
      :before-close="handleClose"
      >
      <div class="form-class">
        <el-form
        label-width="80px"
        :model="form"
        label-position="top"
        :rules="rules" ref="dialog">

          <el-row>
            <el-col :span="12"  v-if="form.parent_id!==0">
              <el-form-item label="上级组织" prop='parent_name'>
                <el-select v-model="form.parent_name" placeholder="请选择上级组织" class="form-half-input">
                  <el-option label="区域一" value="0" class="form-option" >
                    <el-tree ref="tree" :data="parentData" default-expand-all node-key="id"  @node-click="handleNodeClick"  :props="defaultProps"/>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="组织编码" prop='code'>
                <el-input class="form-half-input" v-model="form.code"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <el-form-item label="组织名称" prop='name'>
                <el-input class="form-half-input" v-model="form.name"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="组织全称" prop='full_name'>
                <el-input class="form-half-input" v-model="form.full_name"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <el-form-item label="排序" prop='sort'>
                <el-input class="form-half-input" v-model="form.sort"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="类型" prop='type'>
                <el-select class="form-half-input" v-model="form.type" placeholder="请选择">
                  <el-option  v-for="item in typeData"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <el-form-item label="负责人">
                <el-input class="form-half-input" v-model="form.charge_person"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="办公电话">
                <el-input class="form-half-input" v-model="form.phone"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <el-form-item label="联系地址">
                <el-input class="form-half-input" v-model="form.address"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="邮政编码">
                <el-input class="form-half-input" v-model="form.postal_code"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <el-form-item label="电子邮箱">
                <el-input class="form-half-input"  v-model="form.email"></el-input>
              </el-form-item>
            </el-col>
            <!-- 暂时不用状态功能 后端容易出问题 先写死 -->
            <!-- <el-col :span="12">
              <el-form-item label="状态">
                <el-radio-group v-model="form.status">
                  <el-radio-button label="1">正常</el-radio-button>
                  <el-radio-button label="0">停用</el-radio-button>
                </el-radio-group>
              </el-form-item>
            </el-col> -->
          </el-row>

          <el-form-item label="备注">
            <el-input class="form-input" type="textarea" v-model="form.remarks"></el-input>
          </el-form-item>
          <el-form-item class="bottom-btn">
            <el-button size="mini" @click="handleClose">取消</el-button>
            <el-button size="mini" type="primary" @click="onSave">保存</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { Tree } from 'element-ui';
import { dictSelect } from "@/api/saas";
import { editDept, getDept } from '@/api/saasManage'
import { deepClone } from '@topology/core';
export default {
  name:'EditDrawer',
  components: {
    'el-tree': Tree
  },
  props: {
    drawer:{
      type:Boolean,
      default:false
    },
    editData:{
      type:Object,
      default:() => {}
    }
  },

  data() {
    return {
      typeData:null,// 类型选择的数据
      drawers:false,
      form:{},
      parentData:[{
        name:'顶级组织',
        id:0,
        children:[]
      }],
      defaultProps: {
        children: "children",
        label: "name",
      },
      rules:{
        code:[
           { required: true, message: '组织编码不能为空', trigger: 'blur' },
        ],
        name:[
           { required: true, message: '组织名称不能为空', trigger: 'blur' },
        ],
        type:[
           { required: true, message: '组织类型不能为空', trigger: 'blur' },
        ],
        parent_name:[
           { required: true, message: '上级组织不能为空', trigger: 'blur' },
        ],
      }
    };
  },
  watch: {
    drawer(newVal){
      this.drawers = newVal;
    },
    editData(newVal){
      if(newVal && newVal.id){
         this.form = deepClone(newVal);
      }
    }
  },
  mounted() {
    this.getParentData();
  },
  methods: {
    onSave(){
      this.$refs['dialog'].validate((valid) => {
        if (!valid) {
          return false;
        }
        editDept(this.form,this.form.id).then(res=>{
          if(res.data && res.data.code !== 200){
            return false;
          }
          this.$emit('drawerSave');
        }).catch(() => {
          this.$message.error(`保存失败`);
        });
      })

    },
    /**
     * @desc:关闭
     */
    handleClose() {
      this.drawers = false;
      this.$emit('drawerClose');
    },
    /**
     * @desc: 树点击
     */
    handleNodeClick(row){
      this.form.parent_id = row.id;
      this.form.parent_name = row.name;
    },
    getParentData(){
      getDept().then(res=>{
        if(res.data && res.data.code !== 200){
          return false;
        }
        if(res?.data?.data?.data?.length){
          this.parentData = res.data.data.data;
        }

      }).catch(() => {
        this.$message.error(`获取区域数据失败`);
      });
       //类型下拉框的数据
       let typeParam = {
        type:'department'
      }
      dictSelect(typeParam).then((res) => {
        if (res.data && res.data.code !== 200) {
          return false
        }
        if (this.form.parent_type == 2) {
          res?.data?.data[0]?.children.forEach((r) => {
            if (r.name == '按钮') {
              this.typeData = [r]
              this.form.type = r.value
            }
          })
        } else {
          this.typeData = res?.data?.data[0]?.children
        }
      })
      .catch(() => {
        this.$message.error(`获取下拉选择类型错误`)
      })
    },
  },
};
</script>
<style lang="less">
.form-option{
  height: auto;
  background-color: #fff !important;
}
.dept-edit-main{
  text-align:left;
  color: #444;
  .form-class{
    padding: 0px 24px 50px 24px;
    font-size: 14px;
    line-height: 1.5;
    height: 100%;
    overflow: auto;
      .form-half-input{
        width:90%;
      }
      .form-input{
        width:95%;
      }
  }
  .el-form-item__label{
    padding: 0 0 8px !important;
    width:100% !important;
    text-align: left;
  }
  .bottom-btn{
    margin:0;
    position: absolute;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #e8e8e8;
    padding: 10px 16px;
    text-align: right;
    right: 0;
    background: #fff;
    border-radius: 0 0 4px 4px;
    display: flex;
    justify-content: flex-end;
    padding-right: 43px;
  }
}

</style>
