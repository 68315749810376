<template>
  <div class="dept-dialog">
    <el-dialog title="添加组织架构" :fullscreen="dialogFull"  :visible.sync='dialogs' width='800px' :before-close="onClose">
      <template slot="title">
        <div class="avue-crud__dialog__header">
            <span>
              添加组织架构
            </span>
          <div class="avue-crud__dialog__menu" @click="dialogFull? dialogFull=false: dialogFull=true">
            <i class="el-icon-full-screen"></i>
          </div>
        </div>
      </template>

      <el-form
        label-width="80px"
        :model="form"
        label-position="top"
        class="form-class"
        :rules="rules" ref="dialog"
        >

          <el-row :gutter="15">
            <el-col :span="12">
              <el-form-item label="组织类型" prop='type'>
                <el-select class="form-input" v-model="form.type" placeholder="请选择">
                  <el-option
                    v-for="item in typeData"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="上级组织" prop='parent_name'>
                <el-select v-model="form.parent_name" placeholder="请选择上级组织" class="form-input">
                  <el-option label="区域一" value="0" class="form-option" >
                    <el-tree ref="tree" :data="parentData" default-expand-all node-key="id" @node-click="handleNodeClick" :props="defaultProps"/>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="15">
            <el-col :span="12">
              <el-form-item label="组织名称" prop='name'>
                <el-input class="form-input" v-model="form.name"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="组织编码" prop='code'>
                <el-input class="form-input" v-model="form.code"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="15">
            <el-col :span="12">
              <el-form-item label="组织全称" prop='full_name'>
                <el-input class="form-input" v-model="form.full_name"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="排序" prop='sort'>
                <el-input class="form-input" v-model="form.sort"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="15">
            <el-col :span="12">
              <el-form-item label="负责人">
                <el-input class="form-input" v-model="form.charge_person"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="办公电话">
                <el-input class="form-input" v-model="form.phone"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="15">
            <el-col :span="12">
              <el-form-item label="联系地址">
                <el-input class="form-input" v-model="form.address"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="邮政编码">
                <el-input class="form-input" v-model="form.postal_code"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="15">
            <el-col :span="12">
              <el-form-item label="电子邮箱">
                <el-input class="form-input" v-model="form.email"></el-input>
              </el-form-item>
            </el-col>
            <!-- 暂时不用状态功能 后端容易出问题 先写死 -->
            <!-- <el-col :span="12">
              <el-form-item label="状态">
                <el-radio-group v-model="form.status">
                  <el-radio-button label="1">正常</el-radio-button>
                  <el-radio-button label="0">停用</el-radio-button>
                </el-radio-group>
              </el-form-item>
            </el-col> -->
          </el-row>

          <el-form-item label="备注">
            <el-input class="form-input" type="textarea" v-model="form.remarks"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer">
          <el-button size="mini" @click="onClose">取消</el-button>
          <el-button size="mini" type="primary" @click="onSave">保存</el-button>
        </div>
    </el-dialog>
  </div>
</template>

<script>
import { Tree  } from 'element-ui';
import { dictSelect } from "@/api/saas";
import { addDept, getDept } from '@/api/saasManage'
export default {
  name:'AddDialog',
  components: {
    'el-tree': Tree
  },
  props: {
     dialog:{
      type:Boolean,
      default:false,
    },
    parentParam:{
      type:Object,
      default:()=>{}
    }
  },
  data() {
    return {
      typeData:null, // 类型下拉选择框
      parentData:[{
        name:'顶级组织',
        id:0,
        children:[]
      }],
      dialogs:false,
      dialogFull:false,
      form:{
        postal_code:'',// 邮政编码
        address:'', // 办公地址
        email:'', // 邮箱
        phone:'',// 电话
        name:'',//组织名称
        charge_person:'', //负责人
        full_name:'', // 全称
        remarks:'',// 备注
        type:'',
        status:1,//状态（0：停用 1:正常 ）
        sort:'', // 排序
        code:'', // 编码
        parent_id:'',//父级id
        parent_name:'',//父级名称
      },
      defaultProps: {
        children: "children",
        label: "name",
      },
      rules:{
        code:[
           { required: true, message: '组织编码不能为空', trigger: 'blur' },
        ],
        name:[
           { required: true, message: '组织名称不能为空', trigger: 'blur' },
        ],
        type:[
           { required: true, message: '组织类型不能为空', trigger: 'blur' },
        ],
        parent_name:[
           { required: true, message: '上级组织不能为空', trigger: 'blur' },
        ],
      }
    };
  },
  watch: {
    dialog(newVal){
      this.dialogs = newVal;
    },
    parentParam(newVal){
      this.form.parent_id = newVal.parent_id;
      this.form.parent_name = newVal.parent_name;
    }
  },
  mounted() {
    this.getParentData();
  },
  methods: {
    /**
     * @desc 保存按钮
     */
    onSave(){
      this.$refs['dialog'].validate((valid) => {
        if (valid) {
          addDept(this.form).then(res=>{
            if(res.data && res.data.code !== 200){
              return false;
            }
            this.$emit('dialogSave')
            this.form = {
              parent_name:'',//父级名称
              postal_code:'',// 邮政编码
              address:'', // 办公地址
              email:'', // 邮箱
              phone:'',// 电话
              name:'',//部门名称
              charge_person:'', //负责人
              full_name:'', // 全称
              remarks:'',// 备注
              type:'',
              status:1,//状态（0：停用 1:正常 ）
              sort:'', // 排序
              code:'', // 编码
              parent_id:'',//父级id
            };
          }).catch(() => {
            this.$message.error(`保存失败`);
          });
        } else {
          return false;
        }
      });
    },
    /**
     * @desc:关闭按钮
     */
    onClose() {
      this.dialogs = false;
      this.form = {
        parent_name:'',//父级名称
        postal_code:'',// 邮政编码
        address:'', // 办公地址
        email:'', // 邮箱
        phone:'',// 电话
        name:'',//部门名称
        charge_person:'', //负责人
        full_name:'', // 全称
        remarks:'',// 备注
        type:'',
        status:1,//状态（0：停用 1:正常 ）
        sort:'', // 排序
        code:'', // 编码
        parent_id:'',//父级id
      };
      this.$emit('dialogClose');
    },
    /**
     * @desc:获取上级部门下拉选择框的数据
     * @param
     */
    getParentData(){
      getDept().then(res=>{
        if(res.data && res.data.code !== 200){
          return false;
        }
        this.parentData = res.data.data.data;
      }).catch(() => {
        this.$message.error(`获取区域数据失败`)
      });

      //类型下拉框的数据 这个数据不用更换为手写接口
      let typeParam = {
        type:'department'
      }
      dictSelect(typeParam).then((res) => {
        if (res.data && res.data.code !== 200) {
          return false
        }
        if (this.form.parent_type == 2) {
          res?.data?.data[0]?.children.forEach((r) => {
            if (r.name == '按钮') {
              this.typeData = [r]
              this.form.type = r.value
            }
          })
        } else {
          this.typeData = res?.data?.data[0]?.children
        }
      })
      .catch(() => {
        this.$message.error(`获取下拉选择类型错误`)
      })
    },
    /**
     * @desc: 下拉树选择的数据
     */
    handleNodeClick(row){
      this.form.parent_id = row.id;
      this.form.parent_name = row.name;
    }
  },
};
</script>
<style lang="less">
.dept-dialog{
  .form-class{
    height:400px;
    overflow:auto;
  }
}
</style>
<style lang="less" scoped>
@import "../../../../css/manageAdd.less";
</style>
